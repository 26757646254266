.heading {
  font-size: 1.5rem;
  font-weight: 300;
  letter-spacing: .5ch;
}

.heading-secondary{
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: .5ch;
}

.header h5 {
  font-weight: 300;
}

.flex-center {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.flex-align {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.flex-space-between {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.flex-space-around {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.album-flex-container {
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction:row;
          flex-direction:row;
  -webkit-flex-wrap:wrap;
          flex-wrap:wrap;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.album-card {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  padding: .5rem;
}

.image-card-manage {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 1.5rem;
  width: 70%;
  border: 1px solid grey;
  border-radius: 5px;
  padding: .75rem;
}

.image-field {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-direction:row;
          flex-direction:row;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}


.marginDown {
  margin-top: 100px;
}

@media(max-width: 500px){
  .full-image {
    width: 400px;
  }
}

@media(max-width: 300px){
  .full-image {
    width: 250px;
  }
}

.drag-card {
  border: 1px lightgray solid;
  border-radius: 5px;
  padding: 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
}

.drag-label {
  margin-left: 1rem;
}

.padding-container {
  width: 100%;
  padding: 2rem 5rem;
}

.big-exit {
  -webkit-animation: bigExit .4s ease-in-out;
          animation: bigExit .4s ease-in-out;
  -webkit-animation-direction: forwards;
          animation-direction: forwards;
  opacity: 0;
}

@-webkit-keyframes bigExit {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
    -webkit-filter: hue-rotate(0) invert(0);
            filter: hue-rotate(0) invert(0);
  }
  100% {
    -webkit-transform: scale(1.75);
            transform: scale(1.75);
    opacity: 0;
    -webkit-filter: hue-rotate(90deg) invert(75%);
            filter: hue-rotate(90deg) invert(75%);
  }
}

@keyframes bigExit {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
    -webkit-filter: hue-rotate(0) invert(0);
            filter: hue-rotate(0) invert(0);
  }
  100% {
    -webkit-transform: scale(1.75);
            transform: scale(1.75);
    opacity: 0;
    -webkit-filter: hue-rotate(90deg) invert(75%);
            filter: hue-rotate(90deg) invert(75%);
  }
}

.albums-enter {
  -webkit-animation: slideUp .5s ease-in-out;
          animation: slideUp .5s ease-in-out;
}

.albums-exit {
  animation: slideUp .4s ease-in-out 0s 1 reverse;
}

@-webkit-keyframes slideUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}

.album-image-thumbnail-text {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-transform: translateY(-150px);
          transform: translateY(-150px);
  color: white;
  font-size: 18px;
  font-weight: 600;
}

.image-form-container {
  margin: 2rem 12rem;
}
.album-grid-container {
  margin: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, 325px);
  grid-column-gap: 10px;
  grid-row-gap: 50px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.album-image-thumbnail-text {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: #303030;
  font-size: 18px;
  font-weight: 600;
  opacity: 0;
  transition: 1.5s all;
  pointer-events: none; }

.album-image-thumbnail {
  position: relative; }
  .album-image-thumbnail:hover {
    -webkit-transform: scale(1.12);
            transform: scale(1.12);
    z-index: 10;
    -webkit-transform-origin: center;
            transform-origin: center;
    transition: 1s all; }
    .album-image-thumbnail:hover .album-image-thumbnail-text {
      opacity: 1; }
  .album-image-thumbnail > *:hover {
    -webkit-filter: opacity(50%);
            filter: opacity(50%);
    transition: 1s all;
    cursor: pointer; }

/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */

 
 .la-square-spin,
 .la-square-spin > div {
     position: relative;
     box-sizing: border-box;
 }
 
 .la-square-spin {
     display: block;
     font-size: 0;
     color: #666;
 }
 
 .la-square-spin.la-dark {
     color: #333;
 }
 
 .la-square-spin > div {
     display: inline-block;
     float: none;
     background-color: currentColor;
     border: 0 solid currentColor;
 }
 
 .la-square-spin {
     width: 32px;
     height: 32px;
 }
 
 .la-square-spin > div {
     width: 100%;
     height: 100%;
     border-radius: 0;
     -webkit-animation: square-spin 3s 0s cubic-bezier(.09, .57, .49, .9) infinite;
             animation: square-spin 3s 0s cubic-bezier(.09, .57, .49, .9) infinite;
 }
 
 .la-square-spin.la-sm {
     width: 16px;
     height: 16px;
 }
 
 .la-square-spin.la-2x {
     width: 64px;
     height: 64px;
 }
 
 .la-square-spin.la-3x {
     width: 96px;
     height: 96px;
 }
 
 /*
  * Animation
  */
 @-webkit-keyframes square-spin {
     0% {
         -webkit-transform: perspective(100px) rotateX(0) rotateY(0);
                 transform: perspective(100px) rotateX(0) rotateY(0);
     }
     25% {
         -webkit-transform: perspective(100px) rotateX(180deg) rotateY(0);
                 transform: perspective(100px) rotateX(180deg) rotateY(0);
     }
     50% {
         -webkit-transform: perspective(100px) rotateX(180deg) rotateY(180deg);
                 transform: perspective(100px) rotateX(180deg) rotateY(180deg);
     }
     75% {
         -webkit-transform: perspective(100px) rotateX(0) rotateY(180deg);
                 transform: perspective(100px) rotateX(0) rotateY(180deg);
     }
     100% {
         -webkit-transform: perspective(100px) rotateX(0) rotateY(360deg);
                 transform: perspective(100px) rotateX(0) rotateY(360deg);
     }
 }
 @keyframes square-spin {
     0% {
         -webkit-transform: perspective(100px) rotateX(0) rotateY(0);
                 transform: perspective(100px) rotateX(0) rotateY(0);
     }
     25% {
         -webkit-transform: perspective(100px) rotateX(180deg) rotateY(0);
                 transform: perspective(100px) rotateX(180deg) rotateY(0);
     }
     50% {
         -webkit-transform: perspective(100px) rotateX(180deg) rotateY(180deg);
                 transform: perspective(100px) rotateX(180deg) rotateY(180deg);
     }
     75% {
         -webkit-transform: perspective(100px) rotateX(0) rotateY(180deg);
                 transform: perspective(100px) rotateX(0) rotateY(180deg);
     }
     100% {
         -webkit-transform: perspective(100px) rotateX(0) rotateY(360deg);
                 transform: perspective(100px) rotateX(0) rotateY(360deg);
     }
 }
 
.modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60%;
  margin-right:-50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: rgb(255, 255, 255);
  border: 1px solid #f5f5f5;
  padding: 2rem;
  border-radius: 3px;
}

.modal-container:focus {
  outline: 0;
}

.modal-container-entering {
  opacity: 0;
}

.modal-container-entered {
  -webkit-animation: fadeIn .3s;
          animation: fadeIn .3s;
  -webkit-animation-direction: forwards;
          animation-direction: forwards;
}

.modal-container-exiting {
  -webkit-animation: fadeOut .3s;
          animation: fadeOut .3s;
  -webkit-animation-direction: forwards;
          animation-direction: forwards;
}

.modal-container-exited {
  opacity: 0;
  -webkit-transform: translate(-50%, -500%);
          transform: translate(-50%, -500%);
}

.close-modal-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.close-modal {
  background-color: #777;
  color: white;
  padding: 0 .5rem;
  border-radius: 3px;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;

  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;

  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.ReactModal__Overlay {
  background-color: rgb(150,150,150);
  z-index: 1;
}

.ReactModal__Content {
  border: none;
}
.photo-card {
  position: relative;
}

.back-button {
  position: absolute;
  top: 40%;
  cursor: pointer;
  left:-1%;
}

.nav-button svg {
    width: 50px;
    height: 50px;
    fill: #666;
}

.forward-button {
  position: absolute;
  top: 40%;
  cursor: pointer;
  right: -1%;
}
