.photo-card {
  position: relative;
}

.back-button {
  position: absolute;
  top: 40%;
  cursor: pointer;
  left:-1%;
}

.nav-button svg {
    width: 50px;
    height: 50px;
    fill: #666;
}

.forward-button {
  position: absolute;
  top: 40%;
  cursor: pointer;
  right: -1%;
}