.heading {
  font-size: 1.5rem;
  font-weight: 300;
  letter-spacing: .5ch;
}

.heading-secondary{
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: .5ch;
}

.header h5 {
  font-weight: 300;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.flex-align {
  display: flex;
  align-items: center;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.flex-space-around {
  display: flex;
  justify-content: space-around;
}

.album-flex-container {
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  justify-content: space-around;
}

.album-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: .5rem;
}

.image-card-manage {
  display: flex;
  flex-direction: column;
  margin: 1.5rem;
  width: 70%;
  border: 1px solid grey;
  border-radius: 5px;
  padding: .75rem;
}

.image-field {
  display: flex;
  justify-content: space-between;
  flex-direction:row;
  align-items: flex-start;
}


.marginDown {
  margin-top: 100px;
}

@media(max-width: 500px){
  .full-image {
    width: 400px;
  }
}

@media(max-width: 300px){
  .full-image {
    width: 250px;
  }
}

.drag-card {
  border: 1px lightgray solid;
  border-radius: 5px;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.drag-label {
  margin-left: 1rem;
}

.padding-container {
  width: 100%;
  padding: 2rem 5rem;
}

.big-exit {
  animation: bigExit .4s ease-in-out;
  animation-direction: forwards;
  opacity: 0;
}

@keyframes bigExit {
  0% {
    transform: scale(1);
    opacity: 1;
    filter: hue-rotate(0) invert(0);
  }
  100% {
    transform: scale(1.75);
    opacity: 0;
    filter: hue-rotate(90deg) invert(75%);
  }
}

.albums-enter {
  animation: slideUp .5s ease-in-out;
}

.albums-exit {
  animation: slideUp .4s ease-in-out 0s 1 reverse;
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.album-image-thumbnail-text {
  display: flex;
  justify-content: center;
  transform: translateY(-150px);
  color: white;
  font-size: 18px;
  font-weight: 600;
}

.image-form-container {
  margin: 2rem 12rem;
}